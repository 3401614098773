import React from 'react'

// Import Components
import { LeaveBehindForm } from '@jelly/templates'

const META = {
	canonical: `${process.env.GATSBY_SITE_HOST}/lp/compare-business-phone-systems/form`,
	description:
		'Answer a few questions about your purchasing needs and we’ll provide you with customized Business Phone System quotes from top-rated sellers today.',
	robots: 'noindex, nofollow',
	title: 'Get Fast, Reliable Quotes on Our Top Business Phone Systems - business.com',
}

export default function SqlForm() {
	return <LeaveBehindForm meta={META} widgetCategoryId="10144" />
}
